import React from 'react'

const Blog = () => {
  return (
    <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2 class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Blog</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">We use an agile approach to test assumptions and connect with the needs of your audience early and often.</p>
      </div> 
      <div class="grid gap-8 lg:grid-cols-2">
          <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div class="flex justify-between items-center mb-5 text-gray-500">
                  <span   class=" dark:text-white bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                  <svg class="mr-1 w-3 h-3 dark:text-white"  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clip-rule="evenodd"></path><path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path></svg>
                      Article
                  </span>
                  <span class="  dark:text-white text-sm">14 days ago</span>
              </div>
              <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><a href="#">Celebrating Women's Day at Hotel Midas Regency</a></h2>
              <p class="mb-5 font-light text-gray-500 dark:text-gray-400">As Women's Day approaches, Hotel Midas Regency is gearing up for a celebration like no other. This special day is not just about acknowledging the achievements of women but also about recognizing the progress made towards gender equality. At Hotel Midas Regency, we believe in empowering women and celebrating their invaluable contributions to society.</p>
              <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-4">
                      <img class="w-7 h-7 rounded-full" src="https://whatsapp-media-library.s3.ap-south-1.amazonaws.com/IMAGE/6430fda23ef1010eabaa10c1/9418972_PHOTO20221010204148.jpg" alt="Jese Leos avatar" />
                      <span class="font-medium dark:text-white">
                          Self
                      </span>
                  </div>
                  <a href="#" class="inline-flex items-center   font-medium text-primary-600 text-blue-500  hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
          </article> 
          <article class="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div class="flex justify-between items-center mb-5 text-gray-500">
                  <span class=" dark:text-white bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
                      <svg class="mr-1 w-3 h-3 dark:text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z" clip-rule="evenodd"></path><path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path></svg>
                      Article
                  </span>
                  <span class="text-sm dark:text-white ">14 days ago</span>
              </div>
              <h2 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"><a href="#">Embrace Elegance: Celebrating Paithani Offer at Hotel Midas Regency</a></h2>
              <p class="mb-5 font-light text-gray-500 dark:text-gray-400">As we celebrate the Paithani offer at Hotel Midas Regency, we invite you to experience the beauty and elegance of these timeless sarees. Whether you're looking to add to your collection or simply admire the artistry behind Paithani, our exclusive offer promises an unforgettable experience. Join us as we embrace tradition, celebrate heritage, and indulge in the allure of Paithani sarees at Hotel Midas Regency.</p>
              <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-4">
                      <img class="w-7 h-7 rounded-full" src="https://whatsapp-media-library.s3.ap-south-1.amazonaws.com/IMAGE/6430fda23ef1010eabaa10c1/9418972_PHOTO20221010204148.jpghttps://github.com/midasreegency/website.git" alt="Bonnie Green avatar" />
                      <span class="font-medium dark:text-white">
                          Self
                      </span>
                  </div>
                  <a href="#" class="inline-flex  items-center font-medium text-primary-600 text-blue-500 hover:underline">
                      Read more
                      <svg class="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  </a>
              </div>
          </article>                  
      </div>  
  </div>
</section>
  )
}

export default Blog